<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4">
          <label for="start-date">From</label>
          <b-form-datepicker
            id="start-date"
            v-model="startDate"
            reset-button
            class="mb-2"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            @input="endDate=setEndDate(startDate, endDate)"
          />
        </b-col>
        <b-col md="4">
          <label for="end-date">To</label>
          <b-form-datepicker
            id="end-date"
            v-model="endDate"
            reset-button
            class="mb-2"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            @input="startDate=setStartDate(startDate, endDate)"
          />
        </b-col>
        <b-col
          md="4"
          class="align-self-center"
        >
          <b-button
            variant="primary"
            :disabled="isBtnLoading"
            @click="getStatForRequestedPeriod()"
          >
            <b-spinner
              v-if="isBtnLoading"
              small
            />
            Set Period
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col class="col-md-12">
        <SalesSummary
          :sales-data="salesData"
          :wallet-data="walletData"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-6">
        <TravelerSummary
          v-if="isDataLoaded"
          :traveller-data="travellerData"
        />
      </b-col>
      <b-col class="col-md-6">
        <FlightSummary
          v-if="isDataLoaded"
          :flight-data="flightData"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-12">
        <AirlineSalesTable
          :table-data="airlineSales"
          :data-loaded="isDataLoaded"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import api from '@/libs/api'
import AirlineSalesTable from '@/views/dashboard/AirlineSalesTable.vue'
import FlightSummary from '@/views/dashboard/FlightSummary.vue'
import TravelerSummary from '@/views/dashboard/TravelerSummary.vue'
import SalesSummary from '@/views/dashboard/SalesSummary.vue'
import calendar from '@/mixins/calendar'

export default {
  name: 'Dashboard',
  components: {
    AirlineSalesTable,
    FlightSummary,
    TravelerSummary,
    SalesSummary,
  },
  mixins: [calendar],
  data() {
    return {
      airlineSales: [],
      walletData: {},
      salesData: {},
      flightData: null,
      travellerData: {},
      isDataLoaded: false,
      startDate: '',
      endDate: '',
      isBtnLoading: false,
    }
  },
  mounted() {
    this.getBizStat()
  },
  methods: {
    getBizStat() {
      api.postData('b2e_dashboard/biz_stat/', true, this.payload())
        .then(response => {
          if (response.data.status) {
            this.airlineSales = response.data.result.airline_sale
            this.flightData = response.data.result.flight
            this.travellerData = response.data.result.traveller
            this.walletData = response.data.result.wallet
            this.salesData = response.data.result.sale
            this.isDataLoaded = true
          }
        }).catch().finally(() => {
          this.isBtnLoading = false
        })
    },
    getStatForRequestedPeriod() {
      this.isBtnLoading = true
      this.getBizStat()
    },
    payload() {
      const formData = {}
      if (this.startDate) {
        formData.start_date = this.startDate
        if (!this.endDate) {
          this.endDate = moment(this.startDate).add(30, 'days').format('YYYY-MM-DD')
          formData.end_date = this.endDate
        }
      }
      if (this.endDate) {
        formData.end_date = this.endDate
        if (!this.startDate) {
          this.startDate = moment(this.endDate).subtract(30, 'days').format('YYYY-MM-DD')
          formData.start_date = this.startDate
        }
      }
      return formData
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
