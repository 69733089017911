<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      show-empty
      empty-text="No matching records found"
      class="mb-0"
    >
      <!-- airline -->
      <template #table-busy>
        <div class="text-center text-primary m-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
      <template #cell(airline)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            rounded
            size="32"
            variant="light-company"
            class="mr-2"
          >
            <b-img
              :src="data.item.airline.logo"
              class="airline-logo"
              alt="avatar img"
            /></b-avatar>
          <div>
            <div class="font-weight-bolder">
              {{ data.item.airline.name }}
            </div>
          </div>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
export default {
  name: 'AirlineSalesTable',
  props: {
    tableData: {
      type: Array,
    },
    dataLoaded: {
      type: Boolean,
    },
  },
  data() {
    return {
      fields: [
        { key: 'airline', label: 'Airline' },
        { key: 'no_of_booking', label: 'Sales' },
        { key: 'total_amount', label: 'Amount', formatter: value => `BDT ${Number(value).toLocaleString()}` },
        { key: 'commission_amount', label: 'Commission', formatter: value => `BDT ${Number(value).toLocaleString()}` },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
.airline-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
