<template>
  <div>
    <Dashboard/>
  </div>
</template>

<script>
import Dashboard from '@/views/dashboard/Dashboard.vue'

export default {
  name: 'Home',
  components: {
    Dashboard,
  },
  methods: {
    //
  },
}
</script>

<style>

</style>
