<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Travelers</b-card-title>
      <b-dropdown
        text="Total number of travelers"
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0"
        size="sm"
      />
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="radialBar"
        height="300"
        :options="travellerRadialBar.chartOptions"
        :series="travellerRadialBar.series"
      />
      <div
        class="d-flex justify-content-between mb-1"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            icon="CircleIcon"
            size="16"
            class="text-primary"
          />
          <span class="font-weight-bold text-capitalize ml-75">Adults</span>
        </div>
        <span>{{ travellerData.adult }}</span>
      </div>
      <div
        class="d-flex justify-content-between mb-1"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            icon="CircleIcon"
            size="16"
            class="text-warning"
          />
          <span class="font-weight-bold text-capitalize ml-75">Children</span>
        </div>
        <span>{{ travellerData.child }}</span>
      </div>
      <div
        class="d-flex justify-content-between mb-1"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            icon="CircleIcon"
            size="16"
            class="text-danger"
          />
          <span class="font-weight-bold text-capitalize ml-75">Infants</span>
        </div>
        <span>{{ travellerData.infant }}</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'TravelerSummary',
  components: {
    VueApexCharts,
  },
  props: {
    travellerData: {
      type: Object,
    },
  },
  data() {
    return {
      travellerRadialBar: {
        series: [Number(this.travellerData.adult), Number(this.travellerData.child), Number(this.travellerData.infant)],
        chartOptions: {
          labels: ['Adults', 'Children', 'Infants'],
          plotOptions: {
            radialBar: {
              size: 150,
              hollow: {
                size: '20%',
              },
              track: {
                strokeWidth: '100%',
                margin: 15,
              },
              dataLabels: {
                value: {
                  fontSize: '1rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  offsetY: 5,
                },
              },
            },
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
          stroke: {
            lineCap: 'round',
          },
          chart: {
            height: 100,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      },
    }
  },
}
</script>

<style scoped>

</style>
