<template>
  <div>
    <b-row>
      <b-col class="col-md-12">
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>Flight Statistics</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                md="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="24"
                        icon="BriefcaseIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ salesData.no_of_booking }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Flights Booked
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                md="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-info"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ salesData.no_of_ticketing }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Flights Purchased
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                md="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-warning"
                    >
                      <feather-icon
                        size="24"
                        icon="ClockIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ salesData.no_of_pending }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Flights Pending
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                md="3"
                sm="6"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-danger"
                    >
                      <feather-icon
                        size="24"
                        icon="XIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ salesData.no_of_cancelled }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Flights Cancelled
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-3">
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="light-info"
            size="45"
          >
            <feather-icon
              size="21"
              icon="DollarSignIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              BDT {{ salesData && salesData.total_amount
                ? Number(salesData.total_amount).toLocaleString('en') : 0 }}
            </h2>
            <span>Amount Spent</span>
          </div>
        </b-card>
      </b-col>
      <b-col class="col-md-3">
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="light-warning"
            size="45"
          >
            <feather-icon
              size="21"
              icon="BoxIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              BDT {{ salesData && salesData.commission_amount
                ? Number(salesData.commission_amount).toLocaleString('en') : 0 }}
            </h2>
            <span>Commission Earned</span>
          </div>
        </b-card>
      </b-col>
      <b-col class="col-md-3">
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="light-primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="CreditCardIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              BDT {{ walletData && walletData.balance ? Number(walletData.balance).toLocaleString('en') : 0 }}
            </h2>
            <span>Available Balance</span>
          </div>
        </b-card>
      </b-col>
      <b-col class="col-md-3">
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="light-danger"
            size="45"
          >
            <feather-icon
              size="21"
              icon="TagIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              BDT {{ walletData && walletData.sale ? Number(walletData.sale).toLocaleString('en') : 0 }}
            </h2>
            <span>Wallet Due</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'SalesSummary',
  props: {
    salesData: {
      type: Object,
    },
    walletData: {
      type: Object,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.card-statistics {
  .card-header {
    padding-left: 1.4rem !important;
  }
  .statistics-body {
    padding: 1rem 1.4rem 1.8rem !important;
  }
}
</style>
